import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import PropTypes from "prop-types";

const FeatureList = ({ features, title, t }) => {
  if (features.length === 0) return null;

  return (
    <Container fluid className="mt-4 mt-sm-5">
      <Row>
        <h1 className="feature-heading" data-aos="fade">
          {t(title)}
        </h1>
      </Row>
      <Row className="mt-0 mt-sm-5 px-lg-4">
        {features.map((feature, index) => (
          <Col
            key={feature.feature_id}
            className="feature d-flex align-items-center mx-4 flex-column py-3 mt-3 mt-sm-3 mt-lg-0"
            data-aos="fade-up"
            data-aos-delay={index * 300}
          >
            <div className="imageDiv">
              <img src={feature.image} alt={feature.title} />
            </div>
            <h6 className="mt-2">{feature.title}</h6>
          </Col>
        ))}
      </Row>
    </Container>
  );
};

// Define prop types
FeatureList.propTypes = {
  features: PropTypes.arrayOf(
    PropTypes.shape({
      feature_id: PropTypes.string.isRequired,
      image: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
    })
  ).isRequired,
  title: PropTypes.string.isRequired,
  t: PropTypes.func.isRequired,
};

export default FeatureList;
