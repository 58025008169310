import { FaqService } from "api";
import { useQuery } from "react-query";
import { toast } from "react-toastify";
const onDefaultError = (error) => {
  toast.error(error.message);
};

const useGetAllFaqData = (onSuccess, onError) => {
  return useQuery(
    "all_faq_data",
    () => {
      return FaqService.getFaqData();
    },
    {
      onSuccess,
      onError,
    }
  );
};

export { useGetAllFaqData };
