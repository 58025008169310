import React from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";

const HeroBanner = ({
  title,
  description,
  buttonText,
  buttonLink,
  imageUrl,
  defaultImage,
  aosTitleDelay = 0,
  aosImageDelay = 300,
  className = "",
}) => {
  return (
    <Container fluid className={`mt-3 mt-sm-5 px-4 px-lg-5 ${className}`}>
      <Row>
        <Col
          md={6}
          sm={12}
          lg={6}
          data-aos="fade-up"
          data-aos-delay={aosTitleDelay}
        >
          <h1 className="hero_heading">
            <div
              dangerouslySetInnerHTML={{
                __html: title,
              }}
            />
          </h1>
          <p className="mt-4 heroPara">{description}</p>
          <Button
            className="gitBtn mt-1 mt-lg-2 mt-xl-3 px-xl-4 py-xl-3 text-decoration-none"
            size="lg"
          >
            <Link to={buttonLink} className="text-text-decoration-none">
              {buttonText}
            </Link>
          </Button>
        </Col>
        <Col
          md={6}
          sm={12}
          lg={6}
          className="heroImg d-flex justify-content-center my-3 my-md-0 overflow-hidden"
        >
          <img
            src={imageUrl || defaultImage}
            alt=""
            data-aos="fade-left"
            data-aos-delay={aosImageDelay}
          />
        </Col>
      </Row>
    </Container>
  );
};

// Define default props and prop types
HeroBanner.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  buttonText: PropTypes.string.isRequired,
  buttonLink: PropTypes.string.isRequired,
  imageUrl: PropTypes.string,
  defaultImage: PropTypes.string.isRequired,
  aosTitleDelay: PropTypes.number,
  aosImageDelay: PropTypes.number,
  className: PropTypes.string,
};

HeroBanner.defaultProps = {
  imageUrl: "",
  aosTitleDelay: 0,
  aosImageDelay: 300,
  className: "",
};

export default HeroBanner;
