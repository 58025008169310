import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import "assets/css/terms&conditions.css";
import { useGetCmsData } from "hooks";

function PrivacyPolicy() {
  const { data: getCmsData } = useGetCmsData(
    "privacy-policy",
    () => {},
    (error) => {
      console.log("In this api error occurs " + error.message);
    }
  );
  const cmsData = getCmsData?.data;
  console.log("cmsData: ", cmsData);
  return (
    <>
      <Container fluid className="">
        <Row className="mt-5">
          <Col sm={12} className="text-center">
            <h1 className="title text-center w-100">
              {cmsData?.title || `Privacy Policy`}
            </h1>
          </Col>
          <Col sm={12} className="d-flex justify-content-center mt-4">
            <p className="title-para">
              Lorem ipsum dolor sit amet consectetur adipiscing elit Ut et massa
              mi. Aliquam in hendrerit urna. Pellentesque sit amet sapien
              fringilla, mattis ligula consectetur, ultrices mauris. Maecenas
              vitae mattis tellus. Nullam quis imperdiet augue. Vestibulum
              auctor ornare leo, non suscipit.
            </p>
          </Col>
        </Row>
      </Container>
      <Container fluid className="content-div my-5">
        <Row className="p-3 p-sm-4 p-md-5">
          <Col
            sm={12}
            className=" content"
            dangerouslySetInnerHTML={{ __html: cmsData?.description }}
          />
        </Row>
      </Container>
    </>
  );
}

export default PrivacyPolicy;
