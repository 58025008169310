import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import PropTypes from "prop-types";
import "assets/css/style.css";
import { FaFacebook, FaTwitter } from "react-icons/fa";
import { FaInstagram } from "react-icons/fa6";
import { FaYoutube } from "react-icons/fa6";
import { Link, useLocation } from "react-router-dom";
import JsxParser from "react-jsx-parser";

function Footer({ getSettingData, t }) {
  console.log("getSettingData: ", getSettingData);
  const location = useLocation();

  return (
    <div className="main overflow-hidden overflow-x-hidden footer">
      <Container fluid>
        <Row className="p-4 px-5 d-flex align-items-center justify-content-between ">
          <Col
            lg={4}
            sm={3}
            className="d-flex justify-content-center justify-content-xl-start "
          >
            <a
              href={
                getSettingData?.data?.home_setting_footer_instagram_icon_link
              }
              target="_blank"
              rel="noopener noreferrer"
            >
              <JsxParser
                components={{ FaInstagram, FaFacebook, FaTwitter, FaYoutube }}
                jsx={
                  getSettingData?.data?.home_setting_footer_instagram_icon || ""
                }
              />
            </a>
            <a
              href={
                getSettingData?.data?.home_setting_footer_facebook_icon_link
              }
              target="_blank"
              rel="noopener noreferrer"
            >
              <JsxParser
                components={{ FaInstagram, FaFacebook, FaTwitter, FaYoutube }}
                jsx={
                  getSettingData?.data?.home_setting_footer_facebook_icon || ""
                }
              />
            </a>
            <a
              href={
                getSettingData?.data?.home_setting_footer_twitter_icon__link
              }
              target="_blank"
              rel="noopener noreferrer"
            >
              <JsxParser
                components={{ FaInstagram, FaFacebook, FaTwitter, FaYoutube }}
                jsx={
                  getSettingData?.data?.home_setting_footer_twitter_icon_ || ""
                }
              />
            </a>
            <a
              href={getSettingData?.data?.home_setting_footer_youtube_icon_link}
              target="_blank"
              rel="noopener noreferrer"
            >
              <JsxParser
                components={{ FaInstagram, FaFacebook, FaTwitter, FaYoutube }}
                jsx={
                  getSettingData?.data?.home_setting_footer_youtube_icon || ""
                }
              />
            </a>
          </Col>
          <Col
            lg={7}
            sm={9}
            className="d-flex justify-content-center justify-content-md-end footer-links mt-3 mt-sm-0 "
          >
            <Link
              to={`/terms-conditions`}
              className={`footer-link ${
                location.pathname === `/page/terms-conditions`
                  ? "footer-active-class"
                  : ""
              }`}
            >
              <span> {t("front_page.footer_terms_label")}</span>
            </Link>
            <Link
              to={`/privacy-policy`}
              className={`footer-link ${
                location.pathname === `/page/privacy-policy`
                  ? "footer-active-class"
                  : ""
              }`}
            >
              <span> {t("front_page.header_privacy_label")} </span>
            </Link>
            <span className="fspan">
              {getSettingData?.data?.home_setting_footer_copyright_txt || ""}
            </span>
            <span className="dbt">
              DEVELOPED BY&nbsp;
              <a
                href="https://www.technource.com/"
                target="_blank"
                className="tooltip_link"
                rel="noopener noreferrer"
                // title="Custom Software Development"
                data-title="Web App | Mobile App | Custom Software | AI Development"
              >
                TECHNOURCE
              </a>
            </span>
          </Col>
        </Row>
      </Container>
    </div>
  );
}
// PropTypes validation
Footer.propTypes = {
  getSettingData: PropTypes.object.isRequired,
  t: PropTypes.func,
};
export default Footer;
