import React from "react";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";

const SeoHeader = ({ title, description }) => {
  return (
    <Helmet
      meta={[
        {
          name: "title",
          content: title,
        },
        {
          name: "ob:title",
          content: title,
        },
        {
          name: "description",
          content: description,
        },
        {
          name: "og:description",
          content: description,
        },
      ]}
    ></Helmet>
  );
};
SeoHeader.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
};
export default SeoHeader;
