import React from "react";
import PropTypes from "prop-types"; // Import PropTypes
import { Formik } from "formik";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import { toast } from "react-toastify";

import "assets/css/contact.css";
import validationSchema from "./ContactPageValidations";
import { useSendContactQuery } from "hooks";
import img from "assets/images/contact.svg";

function Contact({ getSettingData, t }) {
  const {
    mutate: sendContactQuery,
    isLoading,
    error,
  } = useSendContactQuery(
    (data) => {
      toast.success(data.message);
      // Handle success response
    },
    (error) => {
      toast.error("Failed to send query: " + error.message);
      // Handle error response
    }
  );

  return (
    <>
      <Container fluid className="mb-5">
        <Row className="mt-2 mt-sm-5">
          <Col sm={12} className="title text-center">
            {getSettingData?.data?.contact_setting_contact_title ||
              t("front_page.home_setting_contact_title_static")}
          </Col>
          <Col sm={12} className="d-flex justify-content-center mt-4">
            <p className="title-para">
              {getSettingData?.data?.contact_setting_contact_description ||
                t("front_page.home_setting_contact_description_static")}
            </p>
          </Col>
        </Row>

        <Row className="mt-3 mt-sm-4">
          <Col sm={12} className="contact-heading text-center">
            {getSettingData?.data?.contact_setting_get_in_touch_title}
          </Col>
          <Col sm={12} className="contact-sub-heading text-center">
            {getSettingData?.data?.contact_setting_get_in_touch_subtitle}
          </Col>
          <Col sm={12} className="d-flex justify-content-center mt-4">
            <p className="title-para">
              {getSettingData?.data?.contact_setting_get_in_touch_description}
            </p>
          </Col>
        </Row>

        <Row className="mt-3 mt-sm-4 px-5">
          <Col
            sm={12}
            lg={5}
            className="contact-img d-flex align-items-center justify-content-center"
          >
            <img
              src={
                getSettingData?.data?.contact_setting_get_in_touch_image || img
              }
              alt="Contact"
            />
          </Col>
          <Col sm={12} lg={7} className="p-0 p-sm-5 mt-4 mt-sm-0">
            <Formik
              initialValues={{
                name: "",
                email: "",
                phone: "",
                question: "",
              }}
              validationSchema={validationSchema(t)}
              onSubmit={(values, { setSubmitting, resetForm }) => {
                sendContactQuery(values, {
                  onSuccess: () => {
                    resetForm();
                  },
                });
                setSubmitting(false);
              }}
            >
              {({
                handleSubmit,
                handleChange,
                handleBlur,
                values,
                touched,
                isValid,
                errors,
              }) => (
                <Form noValidate onSubmit={handleSubmit}>
                  <Form.Group className="mb-4">
                    <Form.Label>
                      {t("front_page.contact_us_form_name_label")}
                    </Form.Label>
                    <Form.Control
                      type="text"
                      name="name"
                      placeholder={t(
                        "front_page.contact_us_form_name_placeholder"
                      )}
                      value={values.name}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      isValid={touched.name && !errors.name}
                      isInvalid={touched.name && !!errors.name}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.name}
                    </Form.Control.Feedback>
                  </Form.Group>

                  <Form.Group className="mb-4">
                    <Form.Label>
                      {t("front_page.contact_us_form_email_label")}
                    </Form.Label>
                    <Form.Control
                      type="email"
                      name="email"
                      placeholder={t(
                        "front_page.contact_us_form_email_placeholder"
                      )}
                      value={values.email}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      isValid={touched.email && !errors.email}
                      isInvalid={touched.email && !!errors.email}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.email}
                    </Form.Control.Feedback>
                  </Form.Group>

                  <Form.Group className="mb-4">
                    <Form.Label>
                      {t("front_page.contact_us_form_mobile_number_label")}
                    </Form.Label>
                    <Form.Control
                      type="text"
                      name="phone"
                      placeholder={t(
                        "front_page.contact_us_form_mobile_number_placeholder"
                      )}
                      value={values.phone}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      isValid={touched.phone && !errors.phone}
                      isInvalid={touched.phone && !!errors.phone}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.phone}
                    </Form.Control.Feedback>
                  </Form.Group>

                  <Form.Group className="mb-4">
                    <Form.Label>
                      {t("front_page.contact_us_form_question_label")}
                    </Form.Label>
                    <Form.Control
                      as="textarea"
                      rows={1}
                      name="question"
                      placeholder={t(
                        "front_page.contact_us_form_question_placeholder"
                      )}
                      value={values.question}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      isValid={touched.question && !errors.question}
                      isInvalid={touched.question && !!errors.question}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.question}
                    </Form.Control.Feedback>
                  </Form.Group>

                  <Button type="submit" className="w-100 mt-3 contact-btn">
                    {isLoading
                      ? t("front_page.contact_us_form_sending_label")
                      : t("front_page.contact_us_form_send_label")}
                  </Button>
                  {error && (
                    <div className="mt-3 text-danger">{error.message}</div>
                  )}
                </Form>
              )}
            </Formik>
          </Col>
        </Row>
      </Container>
    </>
  );
}

Contact.propTypes = {
  getSettingData: PropTypes.shape({
    data: PropTypes.shape({
      contact_setting_contact_title: PropTypes.string,
      contact_setting_contact_description: PropTypes.string,
      contact_setting_get_in_touch_title: PropTypes.string,
      contact_setting_get_in_touch_subtitle: PropTypes.string,
      contact_setting_get_in_touch_description: PropTypes.string,
      contact_setting_get_in_touch_image: PropTypes.string,
    }),
  }),
  t: PropTypes.func,
};

export default Contact;
